import { Component, OnInit } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit {
  private headers: any;
  // store the URL so we can redirect after logging in
  public redirectUrl: string;

  constructor(private router: Router, private ssoService: SsoService, private activatedRoute: ActivatedRoute, private http : HttpClient) { }
    loading = false;
    entName: string;
    errorMessage: string;
    returnUrl: string;

      ngOnInit() {
          let entVal ;
          //console.log("Cookie: " + this.getCookie("eu"));
          this.activatedRoute.queryParams.subscribe((params: Params) => {
             entVal = params['name'];
          });
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';

        this.ssoService.loginWithSSO().subscribe(
          (entName:any) => {
            //this.entName = entName._body;
               this.entName = entName;
            //console.log("EntName "+ this.entName);
            let user = this.entName;
            if (user){
              localStorage.setItem('currentUser', user);
            }
           // this.router.navigate([this.returnUrl]);
            this.redirectUrl = '/error';
            if (this.redirectUrl) {
              this.router.navigate([this.redirectUrl]);
              this.redirectUrl = null;
            }
          },
          error => {
            this.loading = false;
          });
      }
}
