import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class SsoService {
  constructor(private http: HttpClient) { }

    initiateSSO(): Observable<any>  {
        return this.http.get('fleet/sso/', {responseType: 'text'});
	}

    validateSSO(): Observable<any> {
        let url = 'fleet/sso/ValidateSSO';
        console.log("URL" , url);
        return this.http
                 .post(url, "");
    }

    initiateOASSSO(): Observable<any>  {
      return this.http.get('fleet/sso/oas', {responseType: 'text'});
    }

    validateOASSSO(): Observable<any> {
      let url = 'fleet/sso/oas/ValidateSSO';
      console.log("URL" , url);
      return this.http
               .post(url, "");
    }

    loginWithSSO() {
      let url = 'fleet/sso/getent';
      return this.http.get(url);
    }

   logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }
}
