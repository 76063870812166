import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class UtilService {
    
    constructor(private http: HttpClient) { }
    
    initiate(): Observable<any>  {
        /*let url = 'fleet/';
        console.log("URL", url);*/
        return this.http.get('fleet/');
    }
    
    getAnnouncements(): Observable<any> {
        return this.http.get('aiedecisiontoolserver/aiedecision/announcements/');
    }
}
