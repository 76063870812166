import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { UtilService } from './util.service';
import { SsoService } from './sso/sso.service';
import { SsoComponent } from './sso/sso.component';
import { GeotabComponent } from './geotab/geotab.component';
import {FleetPortalInterceptor} from './fleetportal.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    SsoComponent,
    GeotabComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    RouterModule.forRoot([
        {
            path: 'validatesso',
            component: SsoComponent
        },
        {
            path: 'geotab',
            component: GeotabComponent
        }
    ])
  ],
  providers: [
    UtilService,
    SsoService,
    { provide: HTTP_INTERCEPTORS, useClass: FleetPortalInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
