import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class FleetPortalInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let httpsReq ;
        // If you are calling an outside domain then do not add the token.
        console.log('fleetportalserver req :  ' +  req.url);
        // clone request and replace '/fleetportalserver' with '' at the same time
        httpsReq = req.clone({
            url: '/fleetportalserver' + req.url
        });
        if (httpsReq && httpsReq.url !== undefined) {
            if (httpsReq.url.indexOf('fleetportalserver/') === -1) {
                httpsReq = req.clone({
                    url: httpsReq.url.replace('fleetportalserver', 'fleetportalserver/')
                });
            }
            if (httpsReq.url.indexOf('fleetportal/fleetportalserver/') > -1) {
                httpsReq = req.clone({
                    url: httpsReq.url.replace('fleetportal/fleetportalserver/', 'fleetportalserver/')
                });
            }
            if (httpsReq.url.indexOf('fleetportalserver/aiedecisiontoolserver/') > -1) {
                httpsReq = req.clone({
                    url: httpsReq.url.replace('fleetportalserver/aiedecisiontoolserver/', 'aiedecisiontoolserver/')
                });
            }
            console.log('fleetportalInterceptor final url :  ' + httpsReq.url);
        }
        return next.handle(httpsReq);
    }
}
