import { Component } from '@angular/core';
import { UtilService } from './util.service';
import { SsoService } from './sso/sso.service';
import {Announcement} from "./announcement";
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './portal.css']
})



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './portal.css']
})

export class AppComponent {
    title = 'app';
    os = "";
    device = "";
    ssoInitResult: string;
    redirectUrl: string;
    
    constructor(private utilService: UtilService, private deviceService: DeviceDetectorService,
        private ssoService: SsoService) {
        this.os = this.deviceService.getDeviceInfo().os;
        //console.log("OS :" + this.os);
        this.device = this.deviceService.getDeviceInfo().device;
        //console.log("Device :" + this.device);
    }
    
    host: string;
    internal: boolean;
    errorMessage: string;
    
    ampUrl: string;
    autoAuctonsUrl: string;
    driveThruUrl: string;
    fedFMSUrl: string;
    armUrl: string;
    fmsUrl: string;
    fleetWebUrl: string;
    strUrl: string;
    drmUrl: string;
    vfeUrl: string;
    autoChoiceUrl: string;
    autoVendorUrl: string;
    fvsUrl: string;
    roadsUrl: string;
    fmvrsUrl: string;
    aieDecisionUrl: string;
    announcements:Array<Announcement> = [];
    fms2goUrl: string;
    fleet2goUrl: string;
    geoTabUrl: string;
    
    ngOnInit() {
        console.log("In ngOnInit ...");
        this.utilService.initiate()
             .subscribe(
               response => {
                   this.host = response.host;
                   console.log("Host : " + this.host);
                   if( this.host == "localhost" || (null != response.internal && response.internal == "1") ) {
                        this.internal = true;
                   } 
                   else { 
                        this.internal = false;
                   }
                   this.host = this.host.trim();
                   if( this.host == "fleet.gsa.gov") {
                       this.ampUrl = "https://amp.fas.gsa.gov";
                       this.autoAuctonsUrl = "https://autoauctions.gsa.gov/GSAAutoAuctions/";
                       this.driveThruUrl = "https://drivethru.gsa.gov/";
                       this.armUrl = "https://arm.fas.gsa.gov/WebARM/";
                       this.fmsUrl = "https://fms.fas.gsa.gov";
                       this.fleetWebUrl = "https://fleet.fas.gsa.gov";
                       this.aieDecisionUrl = "https://fleet.gsa.gov/aiedecision/";
                       this.strUrl = "https://str.gsa.gov/";
                       this.vfeUrl = "https://drivethru.gsa.gov/VFE.htm";
                       this.autoChoiceUrl = "https://autochoice.fas.gsa.gov";
                       this.autoVendorUrl = "https://autovendor.fas.gsa.gov/";
                       this.fvsUrl = "https://vehiclestd.fas.gsa.gov/";
                       this.roadsUrl = "https://roads.fas.gsa.gov/";
                       this.geoTabUrl = "https://gov1.geotabgov.us/GSAFleet";
                   }
                   else if( this.host == "fleet-dr.gsa.gov") {
                       this.ampUrl = "https://amp-dr.fas.gsa.gov";
                       this.autoAuctonsUrl = "https://autoauctions-dr.fas.gsa.gov/GSAAutoAuctions/";
                       this.driveThruUrl = "https://drivethru-dr.fas.gsa.gov/";
                       this.armUrl = "https://arm-dr.fas.gsa.gov/WebARM/";
                       this.fmsUrl = "https://fms-dr.fas.gsa.gov";
                       this.fleetWebUrl = "https://fleet-dr.fas.gsa.gov";
                       this.aieDecisionUrl = "https://fleet-dr.gsa.gov/aiedecision/";
                       this.strUrl = "https://str-dr.gsa.gov/";
                       this.vfeUrl = "https://drivethru-dr.gsa.gov/VFE.htm";
                       this.autoChoiceUrl = "https://autochoice-dr.fas.gsa.gov";
                       this.autoVendorUrl = "https://autovendor-dr.fas.gsa.gov/";
                       this.fvsUrl = "https://vehiclestd-dr.fas.gsa.gov/";
                       this.roadsUrl = "https://roads-dr.fas.gsa.gov/";
                       this.geoTabUrl = "https://gov1.geotabgov.us/GSAFleet";
                   }
                   else if( this.host == "fleetb.gsa.gov" ) {
                       this.ampUrl = "https://ampb.fas.gsa.gov";
                       this.autoAuctonsUrl = "https://autoauctionsb.fas.gsa.gov/GSAAutoAuctions/";
                       this.driveThruUrl = "https://drivethrub.gsa.gov/";
                       this.armUrl = "https://armb.fas.gsa.gov/WebARM/";
                       this.fmsUrl = "https://fmsb.fas.gsa.gov";
                       this.fleetWebUrl = "https://fleetb.fas.gsa.gov";
                       this.aieDecisionUrl = "https://fleetb.gsa.gov/aiedecision/";
                       this.strUrl = "https://strb.gsa.gov/";
                       this.vfeUrl = "https://drivethrub.gsa.gov/VFE.htm";
                       this.autoChoiceUrl = "https://autochoiceb.fas.gsa.gov";
                       this.autoVendorUrl = "https://autovendorb.fas.gsa.gov/";
                       this.fvsUrl = "https://vehiclestdb.fas.gsa.gov/";
                       this.roadsUrl = "https://roadsb.fas.gsa.gov/";
                       this.geoTabUrl = "https://gov1.geotabgov.us/GSAFleet";
                   }
                   else {
                       this.ampUrl = "https://ampdev.fas.gsa.gov";
                       this.autoAuctonsUrl = "https://autoauctionsdev.fas.gsa.gov/GSAAutoAuctions/";
                       this.driveThruUrl = "https://drivethrud.fas.gsa.gov/";
                       this.armUrl = "https://armd.fas.gsa.gov/WebARM/";
                       this.fmsUrl = "https://fmsb.fas.gsa.gov";
                       this.fleetWebUrl = "https://fleetb.fas.gsa.gov";
                       this.aieDecisionUrl = "https://fleetd.gsa.gov/aiedecision/";
                       this.strUrl = "https://strd.gsa.gov/";
                       this.vfeUrl = "https://drivethrud.fas.gsa.gov/VFE.htm";
                       this.autoChoiceUrl = "https://autochoiced.fas.gsa.gov";
                       this.autoVendorUrl = "http://159.142.46.184:8380/AutoVendor/Home";
                       this.fvsUrl = "http://159.142.46.184:8380/CommentCollector/Home";
                       this.roadsUrl = "https://roadsd.fas.gsa.gov/";
                       this.geoTabUrl = "https://gov1.geotabgov.us/GSAFleet";
                   }
               },
               error =>  {
                this.errorMessage = <any>error;
                console.log(this.errorMessage);   
           });
        
        this.utilService.getAnnouncements()
              .subscribe(announcements => {
                this.announcements=announcements;
                //console.log('announcements is', announcements);
        });
        
        // We will display links only of user got to portal page from a device
        if( this.device != "unknown" ) {
            if( this.os == "android" ) {
                this.fms2goUrl = "https://play.google.com/store/apps/details?id=com.unisys.fms2go&hl=en";
                this.fleet2goUrl = "https://play.google.com/store/apps/details?id=gov.gsa.fas.gsafleet2go&hl=en";   
            }
            else if( this.os == "mac" ) {
                this.fleet2goUrl = "https://itunes.apple.com/us/app/gsafleet2go/id1177032715?mt=8";
            }
        }
    }

    invokeSSOforGeoTab() {
        this.ssoService.initiateSSO()
            .subscribe(
                response => {
                    this.ssoInitResult = response;
                    console.log("sso Url "+ this.ssoInitResult);
                    if(this.ssoInitResult === '') {
                        this.errorMessage = "Could not initiate SSO, please try again later!"  
                        return;  
                    }
                this.redirectUrl = this.ssoInitResult;
                if (this.redirectUrl) {
                    window.location.href = this.redirectUrl; 
                }
                },
                error =>  {
                    this.errorMessage = <any>error;
                    console.log(this.errorMessage);  
                    //this.alertService.error(error);
                    //this.loading = false;
                });
    }

    invokeSSOforOASGeoTab() {
        this.ssoService.initiateOASSSO()
            .subscribe(
                response => {
                    this.ssoInitResult = response;
                    console.log("sso Url "+ this.ssoInitResult);
                    if(this.ssoInitResult === '') {
                        this.errorMessage = "Could not initiate SSO, please try again later!"  
                        return;  
                    }
                this.redirectUrl = this.ssoInitResult;
                if (this.redirectUrl) {
                    window.location.href = this.redirectUrl;
                }
                },
                error =>  {
                    this.errorMessage = <any>error;
                    console.log(this.errorMessage);  
                    //this.alertService.error(error);
                    //this.loading = false;
                });
    }
}


